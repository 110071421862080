import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-avator',
  templateUrl: './avator.component.html',
  styleUrls: ['./avator.component.scss'],
})
export class AvatorComponent {
  @Input() thumbnailUrl = ''
  @Input() name = ''

  constructor() {}
}
