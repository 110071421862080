import { Component } from '@angular/core'

import { AuthService } from '../../services/auth.service'

@Component({
  template: `<div
    class="container"
    fxLayout="column"
    fxLayoutAlign="space-between center"
  >
    <h1>{{ 'common.session_expired' | translate }}</h1>
    <div class="message">
      {{ 'common.please_login_again' | translate }}
    </div>
    <app-button-small
      backgroundColor="#15A3B8"
      (click)="this.navigateToLoginPage()"
    >
      OK
    </app-button-small>
  </div>`,
  styles: [
    `
      .container {
        width: 400px;
        height: 250px;
        padding: 1em;
      }
      .message {
        color: #666;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
      }
    `,
  ],
})
export class AuthorizationRequiredDialogComponent {
  constructor(private authService: AuthService) {}

  navigateToLoginPage(): void {
    this.authService.logout()
  }
}
