import { Component, EventEmitter, Output } from '@angular/core'

import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  @Output() readonly closed = new EventEmitter()

  enableAdminPage = false

  constructor(private authService: AuthService) {
    const approvedAny = (...roles: string[]) => {
      return roles.some((role) => this.authService.roles.includes(role))
    }
    this.enableAdminPage = approvedAny(
      'SUPER_ADMIN',
      'BILLING_ADMIN',
      'ENABLER',
    )
  }

  navigateMyPage(): void {
    const url = `${environment.adminAppUrl}/main/admin/my-page`
    window.open(url, '_blank')
    this.closed.emit()
  }

  navigateAdminPage(): void {
    window.open(environment.adminAppUrl, '_blank')
    this.closed.emit()
  }

  logout(): void {
    this.authService.logout()
    this.closed.emit()
  }
}
