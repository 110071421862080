export const getFullName = (
  locale: string,
  firstName: string,
  middleName: string,
  lastName: string,
): string => {
  return locale === 'ja'
    ? concatNames([lastName, middleName, firstName])
    : concatNames([firstName, middleName, lastName])
}

export const getShortName = (
  locale: string,
  firstName: string,
  lastName: string,
): string => {
  return locale === 'ja'
    ? concatNames([lastName, firstName])
    : concatNames([firstName, lastName])
}

const concatNames = (names: string[]): string => {
  return names.filter((name) => name).join(' ')
}
