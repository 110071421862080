<div class="menu">
  <div class="menu-item" (click)="this.navigateMyPage()">
    {{ 'common.user_menu.my_page' | translate }}
  </div>
  <div
    *ngIf="enableAdminPage"
    class="menu-item"
    (click)="this.navigateAdminPage()"
  >
    {{ 'common.admin' | translate }}
  </div>
  <div class="menu-item" (click)="this.logout()">
    {{ 'common.user_menu.logout' | translate }}
  </div>
</div>
