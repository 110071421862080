/* eslint-disable import/order */

import { NgModule } from '@angular/core'

// declarations

const pipes = []

@NgModule({
  declarations: [pipes],
  exports: [pipes],
})
export class CommonPipesModule {}
