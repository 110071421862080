import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { AuthGuard } from './guards/auth.guard'
import { AppLauncherComponent } from './pages/app-launcher/app-launcher.component'
import { MainComponent } from './pages/main/main.component'
import { redirectUnauthorizedToLogin } from './utils/auth-redirects'

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        redirectTo: 'app-launcher',
        pathMatch: 'full',
      },
      {
        path: 'app-launcher',
        component: AppLauncherComponent,
      },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
