import { Component, OnInit } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { map, Observable, tap } from 'rxjs'

import { UserModel } from 'src/app/apollo/types/type'
import { AuthService } from 'src/app/services/auth.service'
import { UserService } from 'src/app/services/user/user.service'
import { environment } from 'src/environments/environment'

interface App {
  logo: string
  name: string
  url: string
}

@Component({
  templateUrl: './app-launcher.component.html',
  styleUrls: ['./app-launcher.component.scss'],
})
export class AppLauncherComponent implements OnInit {
  user: UserModel
  apps: Observable<App[]>

  enableAdminPage = false
  adminAppUrl = environment.adminAppUrl

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getOneSelf()
      .pipe(tap((user) => (this.user = user)))
      .subscribe()

    this.apps = this.getApprovedApps()
    this.enableAdminPage = this.isAdminPageApproved()
  }

  private getApprovedApps(): Observable<App[]> {
    return this.userService.getOneSelf().pipe(
      map((user) => {
        const apps: App[] = []
        const approvedAny = (...roles: string[]) => {
          return roles.some((role) => this.authService.roles.includes(role))
        }
        if (approvedAny('SUPER_ADMIN', 'ENABLER', 'USER')) {
          apps.push({
            logo: 'assets/images/coaching-logo.svg',
            name: this.translate.instant('common.app_launcher.coaching'),
            url: environment.coachingAppUrl,
          })
        }

        if (approvedAny('SUPER_ADMIN', 'ENABLER', 'USER')) {
          apps.push({
            logo: 'assets/images/learning-logo.svg',
            name: this.translate.instant('common.app_launcher.learning'),
            url: `${environment.learningPortalUrl}/main/user`,
          })
        }

        if (approvedAny('SUPER_ADMIN', 'ENABLER') || user.isManager) {
          apps.push({
            logo: 'assets/images/learning-logo.svg',
            name: this.translate.instant('common.app_launcher.learning_admin'),
            url: `${environment.learningPortalUrl}/main/enabler`,
          })
        }

        if (approvedAny('SUPER_ADMIN', 'ENABLER', 'USER')) {
          apps.push({
            logo: 'assets/images/assessment-logo.svg',
            name: this.translate.instant('common.app_launcher.assessment'),
            url: environment.assessmentAppUrl,
          })
        }

        if (approvedAny('SUPER_ADMIN', 'ENABLER')) {
          apps.push({
            logo: 'assets/images/skillmap-logo.svg',
            name: this.translate.instant('common.app_launcher.skillmap'),
            url: environment.skillMapAppUrl,
          })
        }

        if (approvedAny('SUPER_ADMIN', 'ENABLER') || user.isManager) {
          apps.push({
            logo: 'assets/images/dashboard-logo.svg',
            name: this.translate.instant('common.app_launcher.dashboard'),
            url: environment.dashboardAppUrl,
          })
        }

        return apps
      }),
    )
  }

  private isAdminPageApproved(): boolean {
    const approvedAny = (...roles: string[]) => {
      return roles.some((role) => this.authService.roles.includes(role))
    }
    return approvedAny('SUPER_ADMIN', 'BILLING_ADMIN', 'ENABLER')
  }
}
