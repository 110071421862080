import { HttpBackend, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  Auth,
  getAuth,
  User,
  authState,
  signInWithCustomToken,
  signOut,
} from '@angular/fire/auth'

import { jwtDecode } from 'jwt-decode'
import { Observable, timer, Subscription, lastValueFrom, from, of } from 'rxjs'
import { filter, map, scan, mergeMap, catchError } from 'rxjs/operators'

import { environment } from 'src/environments/environment'

import { IdTokenService } from './id-token.service'

interface CheckStatusResult {
  custom_token: string
  token_update_duration: number
}

@Injectable()
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claims: { [key: string]: any }
  isContentPublisher = false
  loading = false
  authConfirmed = false
  orgId: string | undefined
  userId: string | undefined
  languageCode: string | undefined
  timeZoneCode: string | undefined
  regionCode: string | undefined
  roles: string[] = []

  /**
   * emits firebase user user is logged in
   * note: it will emit even if user is logged in from the beginning
   */
  public loggedIn$: Observable<boolean>

  /**
   * emits firebase user or undefined when auth status changes after the application is initialized
   */
  private authChange$: Observable<User | undefined>

  private authChangeSubscription: Subscription

  private checkStatusTimerSubscription?: Subscription

  private auth: Auth

  constructor(
    private dummyAuth: Auth,
    private httpBackend: HttpBackend,
    private idTokeService: IdTokenService,
  ) {}

  /**
   * initializes the auth service. this is expected to be run by app initializer.
   */
  public async initAuth(): Promise<void> {
    try {
      await this.checkStatus()
    } catch (error) {
      this.clearUserState()
      this.navigateLogin()
    }
  }

  /**
   * components may use this method to login the user.
   */
  public navigateLogin(): void {
    const startURL = encodeURI(window.location.href)
    window.location.href = `${environment.loginUrl}/login?startURL=${startURL}`
  }

  /**
   * components may user this method to logout the user
   */
  public async logout(): Promise<void> {
    this.checkStatusTimerSubscription?.unsubscribe()
    this.authChangeSubscription.unsubscribe()
    this.clearUserState()
    await signOut(this.auth)
    const onLogoutURL = encodeURI(
      `${environment.loginUrl}/login?startURL=${location.origin}`,
    )
    window.location.href = `${environment.loginUrl}/auth/logout?onLogoutURL=${onLogoutURL}`
  }

  /**
   * check session status and set the firebase token.
   */
  private async checkStatus(): Promise<void> {
    const result = await lastValueFrom(
      new HttpClient(this.httpBackend).get<CheckStatusResult>(
        `${environment.loginUrl}/auth/check_status`,
        {
          withCredentials: true,
        },
      ),
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedToken = jwtDecode(result.custom_token) as any
    const regionCode = decodedToken.claims.region_code ?? 'ap1'
    const app = getApp(regionCode)
    this.auth = getAuth(app)
    this.authChange$ = authState(this.auth).pipe(
      map((user) => {
        return { loggedIn: !!user, user }
      }),
      scan(
        (prev, curr) => {
          return {
            prevLoggedIn: prev.currLoggedIn,
            currLoggedIn: curr.loggedIn,
            user: curr.user,
          }
        },
        {
          prevLoggedIn: undefined,
          currLoggedIn: undefined,
          user: undefined,
        } as {
          prevLoggedIn: boolean | undefined
          currLoggedIn: boolean | undefined
          user: User | undefined
        },
      ),
      // logged in only
      filter(({ prevLoggedIn, currLoggedIn }) => {
        return prevLoggedIn !== undefined && prevLoggedIn !== currLoggedIn
      }),
      map(({ user }) => user),
    )

    this.loggedIn$ = authState(this.auth).pipe(map((user) => !!user))

    this.authChangeSubscription = this.authChange$.subscribe(async (user) => {
      if (user) {
        console.log('loggedin ')
        await this.setUserState(user)
      } else {
        this.navigateLogin()
      }
    })

    const cred = await signInWithCustomToken(this.auth, result.custom_token)
    await this.setUserState(cred.user)
    this.authConfirmed = true
    // access check_status to update tokens after token_update_duration.
    this.checkStatusTimerSubscription = timer(result.token_update_duration)
      .pipe(
        mergeMap(() => {
          return from(this.checkStatus())
        }),
        catchError(() => {
          this.navigateLogin()
          return of()
        }),
      )
      .subscribe()
  }

  /**
   * sets up the state with the firebase user
   *
   * @param user
   */
  private async setUserState(user: User) {
    const idTokenResult = await user.getIdTokenResult()
    this.idTokeService.idToken = await user.getIdToken()
    this.claims = idTokenResult.claims
    this.orgId = idTokenResult.claims.sfdc_org_id as string
    this.userId = idTokenResult.claims.gcp_user_id as string
    this.languageCode = idTokenResult.claims.language_code as string
    this.timeZoneCode = idTokenResult.claims.time_zone_code as string
    this.regionCode = idTokenResult.claims.region_code as string
    this.roles = (idTokenResult.claims.roles as string[]) ?? []
  }

  /**
   * clears whatever was set by setUserState
   */
  private clearUserState() {
    this.idTokeService.idToken = undefined
    this.claims = undefined
    this.orgId = undefined
    this.userId = undefined
    this.languageCode = undefined
    this.timeZoneCode = undefined
    this.regionCode = undefined
    this.roles = []
  }
}
