import { AuthPipe } from '@angular/fire/auth-guard'

import { pipe } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from 'src/environments/environment'

/**
 * send user to login page if they are not logged in.
 * remember where the user was trying to access and attach it to the parameter.
 */
export const redirectUnauthorizedToLogin = (): AuthPipe => {
  return pipe(
    map((user: firebase.default.User) => {
      if (!user) {
        const startURL = encodeURI(window.location.href)
        window.location.href = `${environment.loginUrl}/login?startURL=${startURL}`
        return false
      }
      return true
    }),
  )
}
