import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-button-small',
  template: `
    <button
      type="button"
      mat-stroked-button
      class="app-button-small"
      [disabled]="this.disabled"
      (click)="this.onButtonClick($event)"
      [ngStyle]="this.getStyle()"
    >
      <ng-content></ng-content>
    </button>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        height: 40px;
        min-width: 120px;
      }
      .app-button-small {
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 20px;
      }
      .app-button-small:lang(ja) {
        font-size: 14px;
      }
    `,
  ],
})
export class ButtonSmallComponent {
  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none'
    }
    return 'auto'
  }

  @Input() backgroundColor = '#008080'
  @Input() color = '#ffffff'

  @Input()
  set disabled(value: boolean) {
    if (value !== null && value !== undefined) {
      // eslint-disable-next-line
      this._disabled = !(value === false || (value as any) === 'false')
    }
  }
  get disabled(): boolean {
    return this._disabled
  }

  @Input()
  set colorReversed(value: boolean) {
    // eslint-disable-next-line
    this._colorReversed = !(value === false || (value as any) === 'false')
  }
  get colorReversed(): boolean {
    return this._colorReversed
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<Event>()

  private _disabled = false
  private _colorReversed = false

  onButtonClick(e: Event): void {
    e.preventDefault()
    e.stopPropagation()
    this.click.emit(e)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStyle(): any {
    if (!this.disabled) {
      return {
        'background-color': this.colorReversed
          ? this.color
          : this.backgroundColor,
        color: this.colorReversed ? this.backgroundColor : this.color,
      }
    }
  }
}
