/* eslint-disable import/order */

import { NgModule } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { FlexLayoutModule } from 'ngx-flexible-layout'
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'

@NgModule({
  exports: [
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatTooltipModule,
  ],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter }],
})
export class MaterialModule {}
