import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { environment } from 'src/environments/environment'

import { AuthorizationRequiredDialogComponent } from '../dialogs/authorization-required/authorization-required-dialog.component'
import { AuthService } from '../services/auth.service'
import { IdTokenService } from '../services/id-token.service'

const PROTECTED_ENDPOINTS = [environment.graphQLURL]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authorizationRequiredDialogRef: MatDialogRef<AuthorizationRequiredDialogComponent>

  constructor(
    private idTokenService: IdTokenService,
    private matDialog: MatDialog,
    private authService: AuthService,
  ) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (PROTECTED_ENDPOINTS.some((endpoint) => req.url.startsWith(endpoint))) {
      const token = this.idTokenService.idToken
      if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        })
        req = req.clone({
          headers: req.headers.set('Region', this.authService.regionCode),
        })
        req = req.clone({
          headers: req.headers.set('API-Type', 'graphql'),
        })
      }
      return next.handle(req).pipe(
        tap({
          error: (error) => {
            if (
              this.isAuthorizationError(error.error) &&
              !this.authorizationRequiredDialogRef
            ) {
              this.authorizationRequiredDialogRef = this.matDialog.open(
                AuthorizationRequiredDialogComponent,
                {
                  disableClose: true,
                },
              )
            }
          },
        }),
      )
    } else {
      return next.handle(req)
    }
  }

  private isAuthorizationError(error: ErrorModel): boolean {
    // an error directly from backend service
    if (error.status) {
      return error.status === 401
    }

    // an error from graphql
    if (error.errors) {
      return error.errors.some((e) => e.extensions?.code === 'UNAUTHENTICATED')
    }
    return false
  }
}

interface ErrorModel {
  status?: number
  errors?: {
    message: string
    extensions: {
      code: string
      exception: {
        stacktrace: string[]
      }
    }
  }[]
}
