import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'

import { InMemoryCache, ApolloLink, DefaultOptions } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { environment } from 'src/environments/environment'

import { AuthInterceptor } from '../../interceptors/auth.interceptor'
import { NoopInterceptor } from '../../interceptors/http.interceptor'

const uri = environment.graphQLURL

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createApollo = (httpLink: HttpLink) => {
  const basic = setContext(() => ({
    headers: {
      Accept: '*/*',
      Region: 'ap1',
    },
  }))

  const auth = setContext(() => {
    return {}
  })

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })])
  const cache = new InMemoryCache()
  return {
    link,
    cache,
    defaultOptions,
  }
}

@NgModule({
  imports: [ApolloModule],
  exports: [HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
