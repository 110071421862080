/* eslint-disable import/order */

import { NgModule } from '@angular/core'

// imports
import { GraphQLModule } from '../apollo/client/apollo-graphql.module'
import { AppRoutingModule } from '../app-routing.module'
import { CommonComponentsModule } from './shared/common-components.module'
import { CommonModulesModule } from './shared/common-modules.module'
import { CommonPipesModule } from './shared/common-pipes.module'
import { MaterialModule } from './shared/material.module'

const modules = [
  AppRoutingModule,
  CommonComponentsModule,
  CommonModulesModule,
  CommonPipesModule,
  GraphQLModule,
  MaterialModule,
]

@NgModule({
  imports: [modules],
  exports: [modules],
})
export class SharedModule {}
