export const environment = {
  production: false,
  loginUrl: 'https://login.staging.enablement-app.dev',
  graphQLURL: 'https://api.staging.enablement-app.dev/graphql',
  coachingAppUrl: 'https://coaching.staging.enablement-app.dev',
  learningPortalUrl: 'https://learning.staging.enablement-app.dev',
  assessmentAppUrl: 'https://assessment.staging.enablement-app.dev',
  skillMapAppUrl: 'https://skill-map.staging.enablement-app.dev',
  dashboardAppUrl: 'https://dashboard.staging.enablement-app.dev',
  adminAppUrl: 'https://admin.staging.enablement-app.dev',
  firebase: {
    ap1: {
      apiKey: 'AIzaSyB_Z2XaNBqaUstA1g-ixbuW9SB4pTfSb60',
      authDomain: 'enablement-app-staging-ap1.firebaseapp.com',
      databaseURL:
        'https://enablement-app-staging-ap1-default-rtdb.firebaseio.com',
      projectId: 'enablement-app-staging-ap1',
      storageBucket: 'enablement-app-staging-ap1.appspot.com',
      messagingSenderId: '661282397080',
      appId: '1:661282397080:web:6f77a3346da56572d3e9ed',
    },
    us1: {
      apiKey: 'AIzaSyCGNk82EmrxpWnFIgPLgn3jMxrgEWoL__I',
      authDomain: 'enablement-app-staging-us1.firebaseapp.com',
      databaseURL:
        'https://enablement-app-staging-us1-default-rtdb.firebaseio.com',
      projectId: 'enablement-app-staging-us1',
      storageBucket: 'enablement-app-staging-us1.appspot.com',
      messagingSenderId: '389962071414',
      appId: '1:389962071414:web:08f377e4c7107133c20e57',
    },
  },
}
