/* eslint-disable import/order */

import { ScrollingModule } from '@angular/cdk/scrolling'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { EaButtonModule } from '@eapp-front/button'

export const HttpLoaderFactory = (httpClient: HttpClient): TranslateLoader => {
  return new TranslateHttpLoader(
    httpClient,
    'assets/i18n/',
    '.json' + `?${new Date().getTime()}`,
  )
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    EaButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ScrollingModule,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModulesModule {}
