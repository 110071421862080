/* eslint-disable import/order */

import { NgModule } from '@angular/core'

// imports
import { AppRoutingModule } from '../../app-routing.module'
import { CommonModulesModule } from './common-modules.module'
import { CommonPipesModule } from './common-pipes.module'
import { MaterialModule } from './material.module'

// declarations
import { AppComponent } from 'src/app/app.component'
import { AuthorizationRequiredDialogComponent } from 'src/app/dialogs/authorization-required/authorization-required-dialog.component'
import { AvatorComponent } from 'src/app/components/avator/avator.component'
import { ButtonLargeComponent } from 'src/app/components/button-large/button-large.component'
import { ButtonMidiumComponent } from 'src/app/components/button-midium/button-midium.component'
import { ButtonSmallComponent } from 'src/app/components/button-small/button-small.component'
import { ButtonTinyComponent } from 'src/app/components/button-tiny/button-tiny.component'
import { PageNotFoundComponent } from 'src/app/components/page-not-found/page-not-found.component'
import { UserMenuComponent } from 'src/app/components/user-menu/user-menu.component'

const components = [
  AppComponent,
  AuthorizationRequiredDialogComponent,
  AvatorComponent,
  ButtonLargeComponent,
  ButtonMidiumComponent,
  ButtonSmallComponent,
  ButtonTinyComponent,
  PageNotFoundComponent,
  UserMenuComponent,
]

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModulesModule,
    CommonPipesModule,
    MaterialModule,
  ],
  declarations: [components],
  exports: [components],
  providers: [],
})
export class CommonComponentsModule {}
