import { gql } from 'apollo-angular'
import { DocumentNode } from 'graphql'

export const getUserOneSelf = (): DocumentNode => {
  return gql`
    query GetUserOneSelf {
      getUserOneSelf {
        id
        firstName
        middleName
        lastName
        nickname
        thumbnailPath
        thumbnailUrl
        email
        isManager
        reportTo {
          email
          firstName
          middleName
          lastName
        }
        department {
          name
        }
        jobTitle {
          name
        }
        language {
          id
          nameJa
          nameEn
          code
        }
        timeZone {
          id
          name
          code
        }
      }
    }
  `
}
