import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { locale } from 'moment'
import { lastValueFrom, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

import { AuthService } from './auth.service'
import { UserService } from './user/user.service'

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  public async init(): Promise<void> {
    this.translate.setDefaultLang('ja')
    await this.authService.initAuth()
    if (!this.authService.authConfirmed) {
      return Promise.reject('Authentication Failed')
    }
    await this.configLanguage()
  }

  private async configLanguage(): Promise<void> {
    await lastValueFrom(
      this.userService.getOneSelf().pipe(
        switchMap((res) => {
          if (res.language?.code) {
            locale(res.language.code)
            return this.translate
              .use(res.language.code)
              .pipe(
                tap(
                  () =>
                    (this.document.documentElement.lang = res.language.code),
                ),
              )
          }
          return of(null)
        }),
      ),
    )
  }
}
