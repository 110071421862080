<div class="header" fxLayoutAlign="start center">
  <div fxFlex="360px" fxLayoutAlign="center center">
    <a [routerLink]="['/']">
      <img
        class="app-logo"
        src="assets/images/app-logo.svg"
        alt="Enablement App Xpotential"
      />
    </a>
  </div>
  <div fxFlex>
    <div class="divider"></div>
  </div>
  <div fxFlex="28px">
    <a
      type="button"
      class="support-page-button"
      mat-icon-button
      [href]="this.SUPPORT_PAGE_URL"
      target="_new"
    >
      <mat-icon [matTooltip]="'common.support_page' | translate"
        >help_outline</mat-icon
      >
    </a>
  </div>
  <div fxFlex="25px"></div>
  <div fxFlex="176px">
    <div
      #userMenuTrigger
      fxLayoutAlign="start center"
      class="user-profile"
      (click)="showUserMenu(userMenuTrigger)"
    >
      <div fxFlex="35px" class="user-avator">
        <app-avator
          [name]="this.user.name"
          [thumbnailUrl]="this.user.thumbnailUrl"
        ></app-avator>
      </div>
      <div fxFlex="12px"></div>
      <div fxFlex="97px" fxLayout="column">
        <div fxFlex="17px" fxLayoutAlign="start end">
          <div class="user-name">{{ this.user?.name }}</div>
        </div>
        <ng-container *ngIf="this.user?.jobTitle?.name">
          <div fxFlex="17px" fxLayoutAlign="start start">
            <div class="user-job-title">{{ this.user?.jobTitle?.name }}</div>
          </div>
        </ng-container>
      </div>
      <div fxFlex="4px"></div>
      <div fxFlex="28px">
        <mat-icon class="expand-icon" color="primary">expand_more</mat-icon>
      </div>
    </div>
  </div>
  <div fxFlex="40px"></div>
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
