import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'

import {
  UserModel,
  DepartmentModel,
  JobTitleModel,
  LicenseModel,
  TimezoneModel,
  RoleModel,
  LanguageModel,
} from 'src/app/apollo/types/type'
import { getFullName } from 'src/app/utils/user-name-util'

import { getUserOneSelf } from './user.query'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private getOneSelfObservable: Observable<UserModel>

  constructor(private apollo: Apollo) {}

  getOneSelf(): Observable<UserModel> {
    if (!this.getOneSelfObservable) {
      this.getOneSelfObservable = this.apollo
        .query<GetUserOneSelf>({
          query: getUserOneSelf(),
        })
        .pipe(
          map((res) => {
            const locale = res.data.getUserOneSelf.language.code
            return this.mappingUser(res.data.getUserOneSelf, locale)
          }),
          shareReplay(1),
        )
    }
    return this.getOneSelfObservable
  }

  mappingUser(user: FetchUserModel, locale: string): UserModel {
    if (!user) return null

    return {
      id: user.id,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      nickname: user.nickname,
      name: getFullName(locale, user.firstName, user.middleName, user.lastName),
      thumbnailUrl: user.thumbnailUrl,
      email: user.email,
      isManager: user.isManager,
      reportTo: this.mappingUser(user.reportTo, locale),
      reportUsers: user.reportUsers?.map((reportUser) =>
        this.mappingUser(reportUser, locale),
      ),
      department: user.department,
      jobTitle: user.jobTitle,
      assignedLicenses: user.assignedLicenses,
      assignedRoles: user.assignedRoles?.map((role) =>
        this.mappingRole(role, locale),
      ),
      language: this.mappingLanguage(user.language, locale),
      timeZone: user.timeZone,
      coachableUsers: user.coachableUsers?.map((u) =>
        this.mappingUser(u, locale),
      ),
    }
  }

  private mappingRole(role: FetchRoleModel, locale: string): RoleModel {
    return {
      id: role.id,
      name: locale === 'ja' ? role.nameJa : role.nameEn,
    }
  }

  private mappingLanguage(
    language: FetchLanguageModel,
    locale: string,
  ): LanguageModel {
    return {
      id: language?.id,
      name: locale === 'ja' ? language?.nameJa : language?.nameEn,
      code: language?.code,
    }
  }
}

interface GetUserOneSelf {
  getUserOneSelf: FetchUserModel
}

export interface FetchUserListResult {
  users: FetchUserModel[]
  userTotalCount: number
}

export interface FetchUserModel {
  id: string
  firstName: string
  middleName?: string
  lastName: string
  nickname?: string
  thumbnailUrl?: string
  email: string
  isManager?: boolean
  reportTo?: FetchUserModel | null
  reportUsers?: FetchUserModel[]
  department?: DepartmentModel | null
  jobTitle?: JobTitleModel | null
  assignedLicenses?: LicenseModel[]
  assignedRoles?: FetchRoleModel[]
  language?: FetchLanguageModel
  timeZone?: TimezoneModel
  coachableUsers?: FetchUserModel[]
}

export interface FetchRoleModel {
  id: string
  nameJa: string
  nameEn: string
}

export interface FetchLanguageModel {
  id?: string
  nameJa?: string
  nameEn?: string
  code: string
}
