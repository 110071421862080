/* eslint-disable import/order */

import { NgModule } from '@angular/core'

// imports
import { SharedModule } from '../shared.module'

// declarations
import { AppLauncherComponent } from 'src/app/pages/app-launcher/app-launcher.component'
import { MainComponent } from 'src/app/pages/main/main.component'

const components = [AppLauncherComponent, MainComponent]

@NgModule({
  imports: [SharedModule],
  declarations: [components],
  exports: [components],
})
export class HomeModule {}
