/* eslint-disable import/order */

import { APP_INITIALIZER, NgModule } from '@angular/core'

// imports
import { SharedModule } from './modules/shared.module'
import { HomeModule } from './modules/home/home.module'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'

// locale
import { registerLocaleData } from '@angular/common'
import localeJa from '@angular/common/locales/ja'

// providers
import { sharedGuards } from './modules/shared-guards'
import { sharedServices } from './modules/shared-services'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { NoopInterceptor } from './interceptors/http.interceptor'

// bootstrap
import { AppComponent } from './app.component'
import { AppLoaderService } from './services/appLoader.service'

import { FIREBASE_OPTIONS } from '@angular/fire/compat'
import { environment } from 'src/environments/environment'

registerLocaleData(localeJa, 'ja')

@NgModule({
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase.ap1)),
    provideFirebaseApp(() => initializeApp(environment.firebase.ap1, 'ap1')),
    provideFirebaseApp(() => initializeApp(environment.firebase.us1, 'us1')),
    provideAuth(() => getAuth()),
    SharedModule,
    HomeModule,
  ],
  providers: [
    sharedGuards,
    sharedServices,
    {
      provide: APP_INITIALIZER,
      useFactory: (appLoader: AppLoaderService) => {
        return () => appLoader.init()
      },
      multi: true,
      deps: [AppLoaderService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase.ap1 },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase.us1 },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
