import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { tap } from 'rxjs'

import { UserModel } from 'src/app/apollo/types/type'
import { UserMenuComponent } from 'src/app/components/user-menu/user-menu.component'
import { UserService } from 'src/app/services/user/user.service'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  user: UserModel
  SUPPORT_PAGE_URL = 'https://r2-company.my.site.com/s'

  private userMenuOverlay: OverlayRef = null

  constructor(
    private overlay: Overlay,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getOneSelf()
      .pipe(tap((user) => (this.user = user)))
      .subscribe()
  }

  showUserMenu(connectToElem: Element): void {
    if (this.userMenuOverlay) {
      this.detachUserMenuOrverlay()
    }

    this.userMenuOverlay = this.overlay.create({
      hasBackdrop: true,
      backdropClass: null,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectToElem)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: -16,
            offsetY: 8,
          },
        ]),
    })

    this.userMenuOverlay
      .backdropClick()
      .subscribe(() => this.detachUserMenuOrverlay())

    const portal = new ComponentPortal(UserMenuComponent)
    const ref = this.userMenuOverlay.attach(portal)
    ref.instance.closed.subscribe(() => this.detachUserMenuOrverlay())
  }

  private detachUserMenuOrverlay = () => {
    if (this.userMenuOverlay) {
      this.userMenuOverlay.detach()
      this.userMenuOverlay.dispose()
      this.userMenuOverlay = null
    }
  }
}
