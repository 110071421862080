import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

const ERROR_MESSAGE =
  'システムでエラーが発生しました。再試行しても問題が解決しない場合はサポートチームにご連絡ください。'

// See the list of error codes.
// https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
const APOLLO_ERROR_CODES = [
  'GRAPHQL_PARSE_FAILED',
  'GRAPHQL_VALIDATION_FAILED',
  'BAD_USER_INPUT',
  'UNAUTHENTICATED',
  'FORBIDDEN',
  'PERSISTED_QUERY_NOT_FOUND',
  'PERSISTED_QUERY_NOT_SUPPORTED',
  'INTERNAL_SERVER_ERROR',
]

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (res) => {
          if ('body' in res && 'errors' in res.body) {
            if (
              res.body.errors.some((error: { extensions: { code: string } }) =>
                APOLLO_ERROR_CODES.includes(error.extensions.code),
              )
            ) {
              this.snackBar.open(ERROR_MESSAGE, 'x', {
                duration: 10000,
                panelClass: ['default-snackbar'],
              })
            }
          }
        },
        (error) => {
          if (error.status === 401) {
            // Error handling will be done in auth.interceptor
          } else if (error.status === 400) {
            this.snackBar.open(ERROR_MESSAGE, 'x', {
              duration: 10000,
              panelClass: ['default-snackbar'],
            })
          } else {
            this.snackBar.open(ERROR_MESSAGE, 'x', {
              duration: 10000,
              panelClass: ['default-snackbar'],
            })
          }
        },
      ),
    )
  }
}
