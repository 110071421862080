<div class="message">
  <div class="hello">
    {{ 'common.app_launcher.hello' | translate: { name: this.user.firstName } }}
  </div>
  <div class="doing-today">
    {{ 'common.app_launcher.doing_today' | translate }}
  </div>
</div>
<div class="launcher">
  <div *ngFor="let app of this.apps | async">
    <a class="app-logo" [href]="app.url">
      <img [src]="app.logo" [alt]="app.name + ' logo'" />
    </a>
    <div class="app-name">
      {{ app.name }}
    </div>
  </div>
</div>
<a *ngIf="enableAdminPage" class="admin-url-link" [href]="this.adminAppUrl">
  <mat-icon class="material-icons-outlined">settings</mat-icon>
  <span>{{ 'common.admin' | translate }}</span>
</a>
